
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

  @Component({
    name: 'Basis'
  })
  @KeepAlive
export default class OndutyAnalysis extends Vue {
    radio = 'result'

    created () {
      // do something
    }

    handleChange (value: string) {
      this.$router.replace('/check/' + value)
    }
}
